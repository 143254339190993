.VideoCard > div{
  display: flex;
  padding: 30px;
  border:1px solid #dadce0;
  border-radius:8px;
  align-items: center;
  justify-content: center;
}
.VideoCard__left > div{
  margin-left: 5px;
} 
.hidden{
  display: none;
}