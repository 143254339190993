.header{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  padding:20px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #232f3e;
  color: #fff;
}
.header__left{
  display: flex; 
  justify-content: center;
  align-items: center;
  flex-direction: row;
  
}
.header__Spotify_icon{
  max-width: 30px;
}
.header__Apple_icon{
  max-width: 30px;
}
.header__Telegram_icon{
  max-width: 30px;
}
.header__YouTube_icon{
  max-width: 30px;
}
.header__left > * {
  margin-left: 10px;
}
.header__search > input {
  flex: 1;
  border: none;
}
.header__interviews > span{
  margin-left: 10px;
  border:1px solid lightgray;
  padding: 10px;
  font-size: 0.75rem;
  font-weight: bold;
}
.header__interviews > span > a{
  text-decoration: none;
  color:white;
}
.header__interviews > span:hover{
  background-color: black;
  color:white;
}
.header__search_icons{
  background-color: #fafafa;
  border-left: 1px solid  lightgray;
  color: gray;
}
.Podcast-Container__content_sorry{
  display: grid;
  grid-template-columns: auto;
}
.title {
  display: block;  
}
.title_payoff{
  font-size: 20px;
  color: #ccc;
}

@media all and (max-width:400px) { 
    .title{
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
    .header__interviews{
      display: flex;
      flex-direction: column;
    }
    .header__interviews span{
      margin:3px;
    }
}
@media all and (max-width:1100px) { 
  /* CSS rules here */
  .header{
    grid-template-columns: auto;       
  }
  .header__interviews {
    margin-top:20px;
  }
  .header__search{
    margin-top:20px;
    order: 1;
    width: 80%;    
  }
  .header__search > input{
    height: 30px;
  }
  .header__Spotify_icon{
    margin-top: 2px;  
    max-width: 40px;
  }
  .header__Apple_icon{
    max-width: 40px;
  }
  .header__Telegram_icon {
    max-width: 40px;
  }
  .header__YouTube_icon{
    max-width: 40px;
  }
  .header__left{
    justify-content: space-around;
  }
}