.Podcast-Container__search{
  display: flex;
  justify-content: center;
  margin-top: 20px;  
}
.Podcast-Container__search > input{
  width: 500px;
  padding: 10px;
}
.Podcast-Container__content{
  display: grid;
  margin: auto;
  margin-top: 50px;
  max-width: 80%;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap:50px;
}
.Podcast-Container__content_sorry{
  font-size: 50px;
}
@media all and (max-width:800px) { 
  .Podcast-Container__content{
    grid-template-columns: auto;
  }
  .Podcast-Container__search > input{
    max-width:300px;
    margin: auto;
  }
}