.Footer{
  position: relative;
  bottom: 0;
  background-color: #232f3e;
  padding: 50px;
  color: #fff;
  max-width: 100%;
  margin: auto;
  text-align: center;
}
.Footer > p > a { 
  color: #cacaca;
  text-decoration: none;
}
.Footer_about__link{
  margin-left: 10px;
}
.Footer__menu_items{
  display: flex;
  justify-content: center;
  list-style-type: none; 
}
.Footer__menu_items > li > a{
  color:#cacaca;
  padding-right: 5px ;
}