.Interview-Content {
  margin-top:50px;
  display: grid;
  max-width: 80%;
  margin: auto;
  grid-template-columns: auto auto auto;
}
.Interview-Content__item > *{
  max-width: 700px;
  margin:10px;
}
.secondary{
  color:lime;
}
.Interviews {
  margin-top: 30px;
}

@media all and (max-width:1100px) { 
  .Interview-Content {
    grid-template-columns: auto ;
  }
  .Interview-Content__item > *{    
    margin: 20px;
  }
}